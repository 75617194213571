


































import {Component, Vue} from 'vue-property-decorator';
import Collapse from "@/components/common/Collapse.vue";
import {AutoAssignmentOptionsRecordDTO} from "@/dto/sublime/assignments/AutoAssignmentOptionsRecordDTO";
import AdminService from "@/services/AdminService";
import {namespace} from "vuex-class";
import SublimeEmployeeDTO from "@/dto/sublime/employee/SublimeEmployeeDTO";
import SublimeEmployeeFilterDTO from "@/dto/sublime/employee/SublimeEmployeeFilterDTO";
import {Role} from "@/constants/Role";

const AppModule = namespace("App");

@Component
export default class AutoAssignmentOptions extends Vue {

  @AppModule.Action
  private startLoading!: () => void

  @AppModule.Action
  private stopLoading!: () => void

  options: Array<[AutoAssignmentOptionsRecordDTO, Array<SublimeEmployeeDTO>]> = [];

  showRolesToAdd = false;

  selectedRole: Role | null = null;

  mounted() {
    this.loadOptions();
  }

  loadOptions() {
    this.startLoading();
    AdminService.getAutoAssignmentOptions().then(
        response => {
          this.stopLoading();
          this.loadEmployees(response.data.map(o => [o, []]));
        },
        error => {
          console.log(JSON.stringify(error));
          this.stopLoading();
        }
    );
  }

  async loadEmployees(options: Array<[AutoAssignmentOptionsRecordDTO, Array<SublimeEmployeeDTO>]>) {
    const promises: Array<Promise<any>> = [];
    this.startLoading();
    options.forEach(o => {
      promises.push(
          AdminService.getEmployeesByRole(o[0].role).then(
              response => o[1] = response.data,
              error => console.log(JSON.stringify(error))
          )
      );
    });
    await Promise.all(promises).then(
        response => {
          this.stopLoading();
          this.options = options;
        },
        error => {
          console.log(JSON.stringify(error));
          this.stopLoading();
        }
    )
  }

  employeeChanged(o: AutoAssignmentOptionsRecordDTO) {
    this.startLoading();
    AdminService.setAutoAssignment(o).then(
        ok => {
          this.stopLoading();
        },
        err => {
          this.stopLoading();
          console.log(JSON.stringify(err));
        }
    );
  }

  roleSelected(){
    this.startLoading();
    AdminService.getEmployeesByRole(this.selectedRole as Role).then(
        ok => {
          this.options.push([new AutoAssignmentOptionsRecordDTO({role: this.selectedRole as Role}), ok.data]);
          this.selectedRole = null;
          this.showRolesToAdd = false;
          this.stopLoading();
        },
        err => {
          this.stopLoading();
          console.log(JSON.stringify(err));
        }
    )
  }

  get getAvailableRolesToAdd(): Array<Role>{
    const assignedRoles = this.options.map(o => o[0].role)
    return this.$roles.filter(role => !assignedRoles.includes(role));
  }


}
