import SublimeEmployeeDTO from "@/dto/sublime/employee/SublimeEmployeeDTO";
import {Role} from "@/constants/Role";

export class AutoAssignmentOptionsRecordDTO {

    public employee: SublimeEmployeeDTO | null = null;
    public role!: Role;

    constructor(init?: Partial<AutoAssignmentOptionsRecordDTO>) {
        Object.assign(this, init);
    }

}